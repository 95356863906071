#top{
	
	.header-logo{
		margin-right: auto !important;
		svg{
			g path.a{
				fill: #E7212B;
			}
		}
	}

	.hero-section-buttons{
		.ep-item-grid-icon{
			font-size: 25px;
		}
	}

	.vertical-button{
		height: 800px !important;
	}

	.ep-item-grid-wrapper:not(.footer--contact-item-grid):not(.footer--address-item-grid){
		.ep-item-grid{
			.ep-item-grid-item-media{
				width: 100% !important;
				padding: 10px;
			}
		}
	}

	.footer--second-row{
		.flex_column{
			&:last-child{
				float: right;
			}
		}
	}

	.footer--socket-row{
		p{
			text-align: right;
		}
	}
}